import { StorageProvider } from "@collaboard/storage-client";
import {
  LegacyBackofficeRuntimeConfig,
  LegacyPages,
} from "./CollaBoardBackOffice/legacyRuntimeConfig.types";
import {
  BackofficeRuntimeConfig,
  Environment,
} from "./CollaBoardBackOffice/runtimeConfig.types";
import { Application } from "./types";

/**
 * Reshape the legacy configuration from /config/env.js to the new schema.
 *
 * @TODO #7992 - Remove this once migrated.
 */
export const parseLegacyRuntimeConfig = (
  legacyRuntimeConfig: LegacyBackofficeRuntimeConfig
): BackofficeRuntimeConfig => {
  return {
    application: Application.CollaBoardBackOffice,
    clientId: "",
    env: inferEnvironment(legacyRuntimeConfig.appName),
    clientAppUrl: legacyRuntimeConfig.clientAppUrl,

    features: {
      ...onlyEnabledValues(legacyRuntimeConfig.pages),
    },

    azure: legacyRuntimeConfig.azure ?? null,

    // This option was not available in 5.15.1 but is required for the new config
    storage: {
      provider: StorageProvider.AzureBlobStorage,
      downloadChunkSize: 1024 * 1024,
      uploadChunkSize: 1024 * 1024,
    },
  };
};

const onlyEnabledValues = (
  pages: Readonly<LegacyPages>
): Partial<BackofficeRuntimeConfig["features"]> => {
  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(pages).filter(([_, v]) => {
      return v === true;
    })
  );
};

/**
 * @NOTE - This isn't perfect but it is only temporary and it only affects the
 * logging of errors to the console.
 */
const inferEnvironment = (appId: string): Environment => {
  switch (appId) {
    case "webapp-local": {
      return "local";
    }
    case "webapp-dev-admin": {
      return "development";
    }
    case "webapp-stg-admin": {
      return "staging";
    }
    case "webapp-test-admin": {
      return "test";
    }
    default: {
      return "production";
    }
  }
};
