import { getRuntimeConfig } from ".";
import { Environment } from "./CollaBoardBackOffice/runtimeConfig.types";

export const getAppVersion = (): string => {
  return `${process.env.REACT_APP_MAJORMINOR_VERSION}.${process.env.REACT_APP_BUILD_VERSION}`;
};
export const getVersionInfo = (): string => {
  const appVersion = getAppVersion();
  const appBuildCommit = process.env.REACT_APP_BUILD_COMMIT;

  return appBuildCommit ? `${appVersion} (${appBuildCommit})` : appVersion;
};
/**
 * Is the app running in development mode?
 *
 * @NOTE this can mean running on the local development server, running on TEST
 * or running on DEV.
 */

export const isDevModeEnabled = (): boolean => {
  const { env } = getRuntimeConfig();
  const developmentEnvironments: Environment[] = [
    "local",
    "development",
    "test",
  ];
  return developmentEnvironments.includes(env);
};
